/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  api: 'https://chat-api-new-pp.contactdve.com/api',
  graphQL: 'https://api.pp.weezchat.fr/graphql',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  gtm: 'GTM-5R8PJLD',
  gtmCountry: 'GTM-NWK8QDR',
  completeProfileInputs: ['phoneNumber'],
  id: '90509be6-e41f-4ed9-9118-e55ddf9c1650',
  dvp4m: true,
  gsv: null,

  appLink: null,
  didomi: null,
};
